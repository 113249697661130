<template>
  <v-container fluid>
    <v-list-item three-line>
      <v-list-item-content>
        <p class="display-1 neuter--text">
          {{ formTitle }}
        </p>
        <v-list-item-subtitle>
          <v-toolbar outlined elevation="0" rounded class="my-4">
            <span class="neuter--text" style=" font-size: 18px">{{
              $t("survey.titles.survey_title") + ": "
            }}</span>
            <span style="font-size: 16px" class="pl-2">
              {{ surveyModel.name ? surveyModel.name : "" }}
            </span>
            <v-icon
              class="pl-4"
              v-if="editedIndexSurvey >= 0 || surveyModel.id > 1"
              color="neuter"
              small
              @click="showAddSurveyDialog = true"
              >mdi-pencil
            </v-icon>
            <!--                        <v-icon class="pl-4" v-if="this.order" small-->
            <!--                                 color="neuter" @click="saveSurveyAction()">mdi-content-save-->
            <!--                        </v-icon>-->
            <v-spacer></v-spacer>
            <span style=" font-size: 18px;">{{
              $t("survey.titles.punctuation") + ": "
            }}</span>
            <span
              style=" font-size: 20px"
              class="pl-2 pr-4 font-weight-bold"
              >{{ parseFloat(surveyPunctuation).toFixed(2) }}
            </span>
          </v-toolbar>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <!--Dialog delete-->
    <v-row justify="center">
      <v-dialog v-model="showDialog" persistent width="600px">
        <v-card>
          <v-card-title class="headline secondary t-bw-secondary--text">{{ title }}</v-card-title>
          <v-card-text class="pa-6">{{ dialogText }}</v-card-text>
          <v-card-actions class="pa-6">
            <v-spacer></v-spacer>
            <v-btn
              small
              text
              color="neuter"
              @click="dialogClose"
              :disabled="loadingDelete"
            >
              {{ $t("common.buttons.cancel") }}
            </v-btn>
            <v-btn
              small
              elevation="0"
              class="t-bw-error--text"
              color="error"
              :loading="loadingDelete"
              @click="deleteItem"
            >
              {{ $t("common.buttons.confirm") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!--        Dialog Add Survey -->
    <v-row justify="center">
      <v-dialog v-model="showAddSurveyDialog" persistent width="30%">
        <v-card :loading="saveLoading">
          <v-card-title class="headline secondary t-bw-secondary--text">
            {{ formDialogTitle }}
          </v-card-title>
          <v-card-text class="pa-6">
            <v-form ref="form5" v-model="valid">
              <v-row justify="center" align-content="start">
                <v-col cols="8">
                  <v-text-field
                   onkeypress="return (event.charCode != 34)" color="primary"
                    v-on:keyup.86="replaceSurveyModelName" v-on:keyup.ctrl.86="replaceSurveyModelName"
                    v-model="surveyModel.name"
                    required
                    outlined
                    dense
                    class="required"
                    :rules="requireAndMaxRules(max)"
                    :counter="max"
                    maxlength="max"
                  ><template v-slot:label>
                                                    {{$t('survey.fields.name')}} <strong class="red--text">*</strong>
                                                </template>
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-checkbox
                    class="mt-1"
                    hide-details
                    color="primary"
                    v-model="surveyModel.signatureRequired"
                    :label="$t('survey.fields.signatureRequired')"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pa-6">
            <v-spacer></v-spacer>
            <v-btn
              small
              class="ml-2"
              text
              color="neuter"
              @click="cancelSurvey"
            >
              {{ $t("common.buttons.cancel") }}
            </v-btn>
            <v-btn
              small
              elevation="0"
              class="ml-2 t-bw-primary--text"
              :disabled="!valid"
              color="primary"
              @click="saveSurveyAction"
            >
              {{ $t("common.buttons.continue") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!--        Dialog Add Section -->
    <v-row justify="center">
      <v-dialog v-model="showAddSectionDialog" persistent width="35%">
        <v-card>
          <v-card-title class="headline secondary t-bw-secondary--text">
            {{ $t("survey.titles.new_section") }}
          </v-card-title>
          <v-card-text class="pa-6">
            <v-form ref="form4" lazy-validation v-model="valid4">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                   onkeypress="return (event.charCode != 34)" color="primary"
                    v-on:keyup.86="replaceName" v-on:keyup.ctrl.86="replaceName"
                    v-model="editedItemSection.name"
                    :label="$t('survey.fields.name')"
                    required
                    outlined
                    dense
                    class="required"
                    :rules="requireAndMaxRules(max)"
                    :counter="max"
                    maxlength="max"
                  />
                </v-col>
                <v-col cols="6">
                  <v-autocomplete :no-data-text="$t('common.fields.noDataAvailable')"
                                  color="primary"
                                  item-color="primary"
                                  deletable-chips
                                  v-model="editedItemSection.responsables"
                                  return-object
                                  item-text="name"
                                  multiple
                                  required
                                  outlined
                                  dense
                                  class="required"
                                  :rules="requireRules"
                                  :label="$t('survey.fields.persons')"
                                  :items="authoritiesList"
                                  small-chips
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip small v-if="index === 0">
                        <span>{{ item.name | truncate(15) }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text caption"
                      >(+{{ editedItemSection.responsables.length - 1 }}
                        {{ $t('common.titles.others') }})</span
                        >
                      </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <!-- <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replacePunctuation" v-on:keyup.ctrl.86="replacePunctuation"
                    color="primary"
                    type="text"
                    :rules="requireRules.concat(onlyDecimalFormat)"
                    v-model="editedItemSection.punctuation"
                    :label="$t('survey.fields.punctuation')"
                    @input="limitSection()"
                  /> -->
                   <v-currency-field
                       :default-value="null"
                       :value-as-integer="false"
                      :decimal-length="2"
                      :auto-decimal-mode="false"
                      locale="en"
                      outlined
                      dense
                      :allow-negative="false"
                      color="primary"
                      :label="$t('survey.fields.punctuation')"
                      v-model="editedItemSection.punctuation"
                    >
                    </v-currency-field>
                  <v-alert
                    :value="
                      editedItemSection.punctuation > 0 && !getValidSectionCalc
                    "
                    :transition="
                      editedItemSection.punctuation > 0 && !getValidSectionCalc
                        ? 'scroll-y-transition'
                        : 'scroll-y-reverse-transition'
                    "
                    border="left"
                    type="error"
                    color="red"
                    dense
                    icon="mdi-alert"
                    text
                    style="font-size: 12px"
                  >
                    {{ getSectionErrorText() }}
                  </v-alert>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pa-6">
            <v-spacer></v-spacer>
            <v-btn
              small
              class="ml-2"
              text
              color="neuter"
              @click="cancelAddSection"
            >
              {{ $t("common.buttons.cancel") }}
            </v-btn>
            <v-btn
              small
              elevation="0"
              class="t-bw-primary--text"
              :disabled="!valid4 || !calcSectionvalid"
              color="primary"
              @click="saveSectionAction"
            >
              {{ $t("common.buttons.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!--        Dialog Add Question-->
    <v-row justify="center">
      <v-dialog v-model="showDialogAddEditQuestion" persistent width="90%">
        <v-card>
          <v-overlay
            class="ml-3"
            :value="overlayQuestion"
            absolute
            color="white"
          >
            <v-progress-circular indeterminate size="64" color="primary" />
          </v-overlay>
          <v-card-title class="headline secondary t-bw-secondary--text">{{
            formQuestionDialogTitle
          }}</v-card-title>
          <v-card-text class="pa-6">
            <v-form ref="form" v-model="valid1">
              <v-row>
                <v-col cols="6">
                  <v-card outlined>
                    <v-card-title class="neuter--text d-block">
                      <p class="ma-0 pa-0">{{ $t("survey.titles.question_data") }}</p>
                      <v-spacer></v-spacer>
                      <span class="d-flex justify-start">
                        <v-switch
                          dense
                          inset
                          class="mr-4 pt-0"
                          v-model="activeExtraQuestion"
                          @click.native="setExtraQuestionFields()"
                          color="primary"
                          :label="$t('survey.fields.extra')"
                          hide-details
                        >
                        </v-switch>
                        <v-switch
                          dense
                          inset
                          class="mr-4 pt-0"
                          v-model="editedItemQuestion.isRequired"
                          color="primary"
                          :label="$t('survey.fields.isRequired')"
                          hide-details
                        >
                        </v-switch>
                        <v-switch
                          dense
                          inset
                          class="mr-4 pt-0"
                          v-model="
                            editedItemQuestion.moreAnswerPointsThanQuestion
                          "
                          color="primary"
                          :label="
                            $t('survey.fields.moreAnswerPointsThanQuestion')
                          "
                          hide-details
                        >
                        </v-switch>
                      </span>
                    </v-card-title>
                    <v-card-text>
                      <v-row v-if="activeExtraQuestion">
                        <v-col cols="12">
                          <v-text-field
                            outlined
                            dense
                           onkeypress="return (event.charCode != 34)" color="primary"
                           v-on:keyup.86="replaceSearch" v-on:keyup.ctrl.86="replaceSearch"
                            v-model="search"
                            append-icon="mdi-magnify"
                            :label="$t('common.titles.filter')"
                            single-line
                            hide-details
                          />
                        </v-col>
                        <v-col cols="12" class="pa-0 ma-0">
                          <v-data-table
                            v-model="editedItemQuestion.extraQuestionFatherRef"
                            :headers="headersExtra"
                            :items="getQuestions()"
                            :search="search"
                            :no-results-text="$t('common.ui.not_search_found')"
                            :no-data-text="$t('common.ui.not_data_found')"
                            :loading-text="$t('common.notification.loadData')"
                            item-key="id"
                            show-select
                            :single-select="true"
                            style="border: 1px solid #d5d5d5"
                            checkbox-color="primary"
                            :footer-props="{
                              ...rowsPerPageItemsOptions,
                              itemsPerPageText: $t(
                                'common.table.itemsPerPageText'
                              ),
                              pageText:
                                '{0}-{1} ' +
                                $t('common.table.pageText') +
                                ' {2}',
                            }"
                          >
                          </v-data-table>
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="
                          editedItemQuestion.extraQuestionFatherRef &&
                            editedItemQuestion.extraQuestionFatherRef.length > 0
                        "
                      >
                        <v-col cols="12">
                          <v-textarea
                           onkeypress="return (event.charCode != 34)" color="primary"
                           v-on:keyup.86="replaceCondicionalAnswer" v-on:keyup.ctrl.86="replaceCondicionalAnswer"
                            v-if="
                              editedItemQuestion.extraQuestionFatherRef[0]
                                .questionStructureType === 'TEXT' ||
                                editedItemQuestion.extraQuestionFatherRef[0]
                                  .questionStructureType === 'LOTE' ||
                                editedItemQuestion.extraQuestionFatherRef[0]
                                  .questionStructureType === 'LACRE' ||
                                editedItemQuestion.extraQuestionFatherRef[0]
                                  .questionStructureType === 'QR'
                            "
                            v-model="editedItemQuestion.condicionalAnswer"
                            :label="$t('survey.titles.conditionalAnswer')"
                            :auto-grow="true"
                            :clearable="true"
                            :no-resize="true"
                            :row-height="40"
                            :rows="3"
                            :rules="requireRules"
                          />
                          <v-autocomplete :no-data-text="$t('common.fields.noDataAvailable')"
                            color="primary"
                            item-color="primary"
                            clearable
                            outlined
                            dense
                            :items="
                              editedItemQuestion.extraQuestionFatherRef[0]
                                .possibleAnswer
                            "
                            :label="$t('survey.titles.conditionalAnswer')"
                            item-text="answer_name"
                            small-chips
                            item-value="answer_name"
                            v-model="editedItemQuestion.condicionalAnswer"
                            required
                            class="required"
                            :rules="requireRules"
                            v-if="
                              selects.includes(
                                editedItemQuestion.extraQuestionFatherRef[0]
                                  .questionStructureType
                              ) ||
                                selectsSimples.includes(
                                  editedItemQuestion.extraQuestionFatherRef[0]
                                    .questionStructureType
                                )
                            "
                          >
                            <template
                              v-slot:selection="{
                                item,
                                index,
                                attrs,
                                select,
                                selected,
                              }"
                            >
                              <v-chip small>
                                <span>{{ item.answer_name }}</span>
                              </v-chip>
                            </template>
                          </v-autocomplete>
                          <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replaceCondicionalAnswer" v-on:keyup.ctrl.86="replaceCondicionalAnswer"
                            v-if="
                              editedItemQuestion.extraQuestionFatherRef[0]
                                .questionStructureType === 'NUMBER'
                            "
                            color="primary"
                            type="text"
                            outlined
                            dense
                            v-model="editedItemQuestion.condicionalAnswer"
                            :rules="requireRules.concat(onlyNumberFormat)"
                            :label="$t('survey.titles.conditionalAnswer')"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="4">
                          <v-select
                            color="primary"
                            item-color="primary"
                            deletable-chips
                            v-model="editedItemQuestion.questionStructureType"
                            :label="$t('survey.fields.type')"
                            required
                            class="required"
                            item-value="value"
                            item-text="text"
                            outlined
                            dense
                            :rules="requireRules"
                            :items="structureType"
                            small-chips
                            @change="changePosibleAnwers($event)"
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="4" v-if="editedItemQuestion.questionStructureType === 'NUMBER'">
                          <v-text-field
                              type="number"
                              clearable
                              outlined
                              dense
                              color="primary"
                              :rules="compareMax(0,5)"
                              v-model="editedItemQuestion.afterPoint"
                          >
                            <template v-slot:label>
                              {{ $t('structure.fields.afterPoint') }}
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replaceQuestionPunctuation" v-on:keyup.ctrl.86="replaceQuestionPunctuation"
                            color="primary"
                            type="text"
                            outlined
                            dense
                            :rules="onlyDecimalFormat"
                            v-model="editedItemQuestion.questionPunctuation"
                            :label="$t('survey.fields.punctuation')"
                            @input="calcLimitSection($event)"
                            :error="
                              limitSectionPunctuation >
                                sectionSelected.punctuation
                            "
                          />
                          <v-alert
                            :value="
                              limitSectionPunctuation >
                                sectionSelected.punctuation
                            "
                            :transition="
                              limitSectionPunctuation >
                              sectionSelected.punctuation
                                ? 'scroll-y-transition'
                                : 'scroll-y-reverse-transition'
                            "
                            border="left"
                            color="red"
                            dense
                            icon="mdi-alert"
                            text
                            style="font-size: 12px"
                          >
                            {{
                              $t("survey.titles.punctuationSection") +
                                limitSectionPunctuation +
                                " > " +
                                sectionSelected.punctuation +
                                " " +
                                $t("survey.titles.punctuationSection2")
                            }}
                          </v-alert>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-textarea
                           onkeypress="return (event.charCode != 34)" color="primary"
                           v-on:keyup.86="replaceText" v-on:keyup.ctrl.86="replaceText"
                            v-model="editedItemQuestion.questionHelp.text"
                            :label="$t('survey.fields.help')"
                            class="required"
                            :clearable="true"
                            :row-height="10"
                            :rows="5"
                            outlined
                            dense
                          >
                          </v-textarea>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card outlined>
                    <v-card-title class="neuter--text">{{
                      $t("survey.titles.question")
                    }}</v-card-title>
                    <v-card-text class="ma-0">
                      <v-row>
                        <v-col cols="12">
                          <v-textarea
                           onkeypress="return (event.charCode != 34)" color="primary"
                           v-on:keyup.86="replaceQuestion" v-on:keyup.ctrl.86="replaceQuestion"
                            v-model="editedItemQuestion.question"
                            :label="$t('survey.fields.question')"
                            required
                            :rules="requireRules"
                            class="required"
                            :clearable="true"
                            :row-height="10"
                            :rows="5"
                            outlined
                            dense
                          >
                          </v-textarea>
                        </v-col>
                      </v-row>

                    </v-card-text>
                  </v-card>
                  <v-card elevation="0" class="ma-0 pa-0">
                    <v-combobox :no-data-text="$t('common.fields.noDataAvailable')"
                                v-model="possibleListAnswer"
                                onkeypress="return (event.charCode != 34)"
                                small-chips
                                clearable
                                :label="$t('survey.fields.answer')"
                                color="primary"
                                multiple
                                v-if="
                              editedItemQuestion.questionStructureType ===
                                'LIST' ||
                              editedItemQuestion.questionStructureType ===
                                'LISTSIMPLE'
                            "
                                required
                                class="required"
                                :rules="requireRules"
                                @change="changeListStructure($event)"
                    >
                      <template
                        v-slot:selection="{
                                index,
                                attrs,
                                item,
                                select,
                                selected,
                              }"
                      >
                        <v-chip
                          small
                          v-bind="attrs"
                          :input-value="selected"
                          close
                          @click:close="removeElementFormList(index)"
                        >
                          {{ translate(item) }}
                        </v-chip>
                      </template>
                      <template v-slot:item="{ object, item, on, attrs }">
                        <div>{{ { name: item } }}</div>
                      </template>
                    </v-combobox>

                    <!--LIST & LISTSIMPLE-->
                    <v-data-table
                      v-if="
                              editedItemQuestion.questionStructureType ===
                                'LIST' ||
                                editedItemQuestion.questionStructureType ===
                                  'LISTSIMPLE'
                            "
                      :headers="headersList"
                      :items="possibleListObjectAnswer"
                      :no-data-text="$t('common.ui.not_data_found')"
                      :loading-text="$t('common.notification.loadData')"
                      v-model="possibleAnswer"
                      style="border: 1px solid #d5d5d5"
                      item-key="id"
                      checkbox-color="primary"
                      :footer-props="{
                              ...rowsPerPageItemsOptions,
                              itemsPerPageText: $t(
                                'common.table.itemsPerPageText'
                              ),
                              pageText:
                                '{0}-{1} ' +
                                $t('common.table.pageText') +
                                ' {2}',
                            }"
                      @item-selected="selectedListItem($event)"
                    >
                      <template v-slot:item.name="{ item }">
                        <span>{{ translate(item.name) }}</span>
                      </template>
                      <template v-slot:item.anwerPuntuation="{ item }">
                        <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replace" v-on:keyup.ctrl.86="replace"
                                      class="ma-1 d-flex justify-center align-center"
                                      dense
                                      outlined
                                      hide-details
                                      color="primary"
                                      v-model="item.anwerPuntuation"
                                      :label="$t('survey.fields.note')"
                                      @input="
                                  filterFloat(
                                    $event,
                                    translate(item.name),
                                    item,
                                    false
                                  )
                                "
                                      :error="warning[translate(item.name)]"
                                      :success="!warning[translate(item.name)]"
                        />
                      </template>
                    </v-data-table>

                    <!--PRODUCT & PRODUCTSIMPLE-->
                    <v-data-table
                      v-if="
                              editedItemQuestion.questionStructureType ===
                                'PRODUCT' ||
                                editedItemQuestion.questionStructureType ===
                                  'PRODUCTSIMPLE'
                            "
                      :headers="headersProducts"
                      :items="products"
                      :no-data-text="$t('common.ui.not_data_found')"
                      :loading-text="$t('common.notification.loadData')"
                      v-model="possibleAnswer"
                      item-key="objectRelId"
                      show-select
                      checkbox-color="primary"
                      style="border: 1px solid #d5d5d5"
                      :search="search"
                      :loading="loadings.products"
                      :options.sync="options"
                      :server-items-length="totalProducts"
                      :footer-props="{
                              ...rowsPerPageItemsOptions,
                              itemsPerPageText: $t(
                                'common.table.itemsPerPageText'
                              ),
                              pageText:
                                '{0}-{1} ' +
                                $t('common.table.pageText') +
                                ' {2}',
                            }"
                      @item-selected="selectedItem($event)"
                      @toggle-select-all="selectItems($event)"
                    >
                      <template v-slot:top>
                        <v-text-field
                          class="pa-4"
                          color="primary"
                          v-model="search"
                          :clearable="true"
                          @keypress.enter="searchForText"
                          @click:clear="searchAll"
                          @click:append="searchForText"
                          append-icon="mdi-magnify"
                          :label="$t('common.titles.filter')"
                          single-line
                          hide-details
                          dense
                          outlined
                        />
                      </template>
                      <template v-slot:item.anwerPuntuation="{ item }">
                        <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replace" v-on:keyup.ctrl.86="replace"
                                      class="ma-1 d-flex justify-center align-center"
                                      dense
                                      outlined
                                      hide-details
                                      color="primary"
                                      v-model="item.anwerPuntuation"
                                      :label="$t('survey.fields.note')"
                                      @input="
                                  filterFloat(
                                    $event,
                                    item.objectRelId,
                                    item,
                                    true
                                  )
                                "
                                      :error="warning[item.objectRelId]"
                                      :success="!warning[item.objectRelId]"
                                      :disabled="!disabledValue[item.objectRelId]"
                        />
                      </template>
                    </v-data-table>

                    <!--EQUIPMENT & EQUIPMENTSIMPLE-->
                    <v-data-table
                      class="mx-0"
                      v-if="
                              editedItemQuestion.questionStructureType ===
                                'EQUIPMENT' ||
                                editedItemQuestion.questionStructureType ===
                                  'EQUIPMENTSIMPLE'
                            "
                      checkbox-color="primary"
                      :headers="headersEquipments"
                      :items="equipments"
                      :no-data-text="$t('common.ui.not_data_found')"
                      :loading-text="$t('common.notification.loadData')"
                      v-model="possibleAnswer"
                      :search="search"
                      :options.sync="options"
                      :loading="loadings.equipments"
                      :server-items-length="totalEquipments"
                      item-key="objectRelId"
                      show-select
                      style="border: 1px solid #d5d5d5"
                      :footer-props="{
                              ...rowsPerPageItemsOptions,
                              itemsPerPageText: $t(
                                'common.table.itemsPerPageText'
                              ),
                              pageText:
                                '{0}-{1} ' +
                                $t('common.table.pageText') +
                                ' {2}',
                            }"
                      @item-selected="selectedItem($event)"
                      @toggle-select-all="selectItems($event)"
                    >
                      <template v-slot:top>
                        <v-text-field
                          class="pa-4"
                          dense
                          outlined
                          color="primary"
                          v-model="search"
                          :clearable="true"
                          @keypress.enter="searchForText"
                          @click:clear="searchAll"
                          @click:append="searchForText"
                          append-icon="mdi-magnify"
                          :label="$t('common.titles.filter')"
                          single-line
                          hide-details
                        />
                      </template>
                      <template v-slot:item.anwerPuntuation="{ item }">
                        <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replace" v-on:keyup.ctrl.86="replace"
                                      class="ma-1 d-flex justify-center align-center"
                                      dense
                                      outlined
                                      hide-details
                                      color="primary"
                                      v-model="item.anwerPuntuation"
                                      :label="$t('survey.fields.note')"
                                      @input="
                                  filterFloat(
                                    $event,
                                    item.objectRelId,
                                    item,
                                    true
                                  )
                                "
                                      :error="warning[item.objectRelId]"
                                      :success="!warning[item.objectRelId]"
                                      :disabled="!disabledValue[item.objectRelId]"
                        />
                      </template>
                    </v-data-table>

                    <!--OPERATOR & OPERATORSIMPLE-->
                    <v-data-table
                      class="mx-0"
                      v-if="
                              editedItemQuestion.questionStructureType ===
                                'OPERATOR' ||
                                editedItemQuestion.questionStructureType ===
                                  'OPERATORSIMPLE'
                            "
                      :headers="headersOperators"
                      :items="operators"
                      :no-data-text="$t('common.ui.not_data_found')"
                      :loading-text="$t('common.notification.loadData')"
                      v-model="possibleAnswer"
                      :search="search"
                      :options.sync="options"
                      :loading="loadings.operators"
                      :server-items-length="totalOperators"
                      item-key="objectRelId"
                      show-select
                      style="border: 1px solid #d5d5d5"
                      :footer-props="{
                              ...rowsPerPageItemsOptions,
                              itemsPerPageText: $t(
                                'common.table.itemsPerPageText'
                              ),
                              pageText:
                                '{0}-{1} ' +
                                $t('common.table.pageText') +
                                ' {2}',
                            }"
                      @item-selected="selectedItem($event)"
                      @toggle-select-all="selectItems($event)"
                    >
                      <template v-slot:top>
                        <v-text-field
                          class="pa-4"
                          dense
                          filled
                          rounded
                          color="primary"
                          v-model="search"
                          :clearable="true"
                          @keypress.enter="searchForText"
                          @click:clear="searchAll"
                          @click:append="searchForText"
                          append-icon="mdi-magnify"
                          :label="$t('common.titles.filter')"
                          single-line
                          hide-details
                        />
                      </template>
                      <template v-slot:item.anwerPuntuation="{ item }">
                        <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replaceAnwerPuntuation" v-on:keyup.ctrl.86="replaceAnwerPuntuation"
                                      class="ma-1 d-flex justify-center align-center"
                                      dense
                                      filled
                                      flat
                                      hide-details
                                      color="primary"
                                      v-model="item.anwerPuntuation"
                                      :label="$t('survey.fields.note')"
                                      @input="
                                  filterFloat(
                                    $event,
                                    item.objectRelId,
                                    item,
                                    true
                                  )
                                "
                                      :error="warning[item.objectRelId]"
                                      :success="!warning[item.objectRelId]"
                                      :disabled="!disabledValue[item.objectRelId]"
                        />
                      </template>
                    </v-data-table>

                    <!--INPUT & INPUTSIMPLE-->
                    <v-data-table
                      class="mx-0"
                      v-if="
                              editedItemQuestion.questionStructureType ===
                                'INPUT' ||
                                editedItemQuestion.questionStructureType ===
                                  'INPUTSIMPLE'
                            "
                      :headers="headersInputs"
                      :items="inputs"
                      :no-data-text="$t('common.ui.not_data_found')"
                      :loading-text="$t('common.notification.loadData')"
                      v-model="possibleAnswer"
                      :search="search"
                      :options.sync="options"
                      :loading="loadings.inputs"
                      :server-items-length="totalInputs"
                      item-key="objectRelId"
                      show-select
                      style="border: 1px solid #d5d5d5"
                      :footer-props="{
                              ...rowsPerPageItemsOptions,
                              itemsPerPageText: $t(
                                'common.table.itemsPerPageText'
                              ),
                              pageText:
                                '{0}-{1} ' +
                                $t('common.table.pageText') +
                                ' {2}',
                            }"
                      @item-selected="selectedItem($event)"
                      @toggle-select-all="selectItems($event)"
                    >
                      <template v-slot:top>
                        <v-text-field
                          class="pa-4"
                          dense
                          outlined
                          color="primary"
                          v-model="search"
                          :clearable="true"
                          @keypress.enter="searchForText"
                          @click:clear="searchAll"
                          @click:append="searchForText"
                          append-icon="mdi-magnify"
                          :label="$t('common.titles.filter')"
                          single-line
                          hide-details
                        />
                      </template>
                      <template v-slot:item.anwerPuntuation="{ item }">
                        <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replace" v-on:keyup.ctrl.86="replace"
                                      class="ma-1 d-flex justify-center align-center"
                                      dense
                                      outlined
                                      hide-details
                                      color="primary"
                                      v-model="item.anwerPuntuation"
                                      :label="$t('survey.fields.note')"
                                      @input="
                                  filterFloat(
                                    $event,
                                    item.objectRelId,
                                    item,
                                    true
                                  )
                                "
                                      :error="warning[item.objectRelId]"
                                      :success="!warning[item.objectRelId]"
                                      :disabled="!disabledValue[item.objectRelId]"
                        />
                      </template>
                    </v-data-table>

                    <!--MEASUREMENTUNIT & MEASUREMENTUNITSIMPLE-->
                    <v-data-table
                      class="mx-0"
                      v-if="
                              editedItemQuestion.questionStructureType ===
                                'MEASUREMENTUNIT' ||
                                editedItemQuestion.questionStructureType ===
                                  'MEASUREMENTUNITSIMPLE'
                            "
                      :headers="headersMeasurements"
                      :items="allUnits"
                      :no-data-text="$t('common.ui.not_data_found')"
                      :loading-text="$t('common.notification.loadData')"
                      v-model="possibleAnswer"
                      :search.sync="search"
                      item-key="objectRelId"
                      show-select
                      style="border: 1px solid #d5d5d5"
                      :footer-props="{
                              ...rowsPerPageItemsOptions,
                              itemsPerPageText: $t(
                                'common.table.itemsPerPageText'
                              ),
                              pageText:
                                '{0}-{1} ' +
                                $t('common.table.pageText') +
                                ' {2}',
                            }"
                      @item-selected="selectedItem($event)"
                      @toggle-select-all="selectItems($event)"
                    >
                      <template v-slot:top>
                        <v-text-field
                          class="pa-4"
                          dense
                          outlined
                          color="primary"
                          v-model="search"
                          :clearable="true"
                          append-icon="mdi-magnify"
                          :label="$t('common.titles.filter')"
                          single-line
                          hide-details
                        />
                      </template>
                      <template v-slot:item.anwerPuntuation="{ item }">
                        <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replace" v-on:keyup.ctrl.86="replace"
                                      class="ma-1 d-flex justify-center align-center"
                                      dense
                                      outlined
                                      hide-details
                                      color="primary"
                                      v-model="item.anwerPuntuation"
                                      :label="$t('survey.fields.note')"
                                      @input="
                                  filterFloat(
                                    $event,
                                    item.objectRelId,
                                    item,
                                    true
                                  )
                                "
                                      :error="warning[item.objectRelId]"
                                      :success="!warning[item.objectRelId]"
                                      :disabled="!disabledValue[item.objectRelId]"
                        />
                      </template>
                    </v-data-table>

                    <!--SEASON & SEASONSIMPLE-->
                    <v-data-table
                      class="mx-0"
                      v-if="
                              editedItemQuestion.questionStructureType ===
                                'SEASON' ||
                                editedItemQuestion.questionStructureType ===
                                  'SEASONSIMPLE'
                            "
                      :headers="headersSeassons"
                      :items="seasons"
                      :no-data-text="$t('common.ui.not_data_found')"
                      :loading-text="$t('common.notification.loadData')"
                      v-model="possibleAnswer"
                      :search="search"
                      :options.sync="options"
                      :loading="loadings.seasons"
                      :server-items-length="totalSeason"
                      item-key="objectRelId"
                      show-select
                      @toggle-select-all="selectItems($event)"
                      style="border: 1px solid #d5d5d5"
                      :footer-props="{
                              ...rowsPerPageItemsOptions,
                              itemsPerPageText: $t(
                                'common.table.itemsPerPageText'
                              ),
                              pageText:
                                '{0}-{1} ' +
                                $t('common.table.pageText') +
                                ' {2}',
                            }"
                      @item-selected="selectedItem($event)"
                    >
                      <template v-slot:top>
                        <v-text-field
                          class="pa-4"
                          dense
                          filled
                          rounded
                          color="primary"
                          v-model="search"
                          :clearable="true"
                          @keypress.enter="searchForText"
                          @click:clear="searchAll"
                          @click:append="searchForText"
                          append-icon="mdi-magnify"
                          :label="$t('common.titles.filter')"
                          single-line
                          hide-details
                        />
                      </template>
                      <template v-slot:item.anwerPuntuation="{ item }">
                        <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replace" v-on:keyup.ctrl.86="replace"
                                      class="ma-1 d-flex justify-center align-center"
                                      dense
                                      filled
                                      flat
                                      hide-details
                                      color="primary"
                                      v-model="item.anwerPuntuation"
                                      :label="$t('survey.fields.note')"
                                      @input="
                                  filterFloat(
                                    $event,
                                    item.objectRelId,
                                    item,
                                    true
                                  )
                                "
                                      :error="warning[item.objectRelId]"
                                      :success="!warning[item.objectRelId]"
                                      :disabled="!disabledValue[item.objectRelId]"
                        />
                      </template>
                    </v-data-table>

                    <v-alert
                      :transition="
                              calcAnwersvalid
                                ? 'scroll-y-transition'
                                : 'scroll-y-reverse-transition'
                            "
                      :value="calcAnwersvalid"
                      border="left"
                      color="red"
                      type="error"
                      dense
                      icon="mdi-alert"
                      text
                      style="font-size: 12px"
                    >{{ $t("survey.titles.limitQuestion") }}
                    </v-alert>
                    <span
                      v-if="
                              selects.includes(
                                editedItemQuestion.questionStructureType
                              ) ||
                                selectsSimples.includes(
                                  editedItemQuestion.questionStructureType
                                )
                            "
                    >
                            <v-alert
                              :transition="
                                alertWarning
                                  ? 'scroll-y-transition'
                                  : 'scroll-y-reverse-transition'
                              "
                              :value="alertWarning"
                              type="error"
                              border="left"
                              color="red"
                              dense
                              icon="mdi-alert"
                              text
                              style="font-size: 12px"
                            >
                              {{ $t("survey.titles.errorFieldPunctuation") }}
                            </v-alert>
                          </span>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pa-6">
            <v-spacer></v-spacer>
            <v-btn
              small
              text
              class="ml-2"
              color="neuter"
              @click="cancelAddEditDialog"
            >
              {{ $t("common.buttons.cancel") }}
            </v-btn>
            <v-btn
              small
              elevation="0"
              color="primary"
              class="t-bw-primary--text"
              @click="saveQuestionAction"
              :disabled="
                !valid1 ||
                  !getCantValidPosivelAnswers(editedItemQuestion) ||
                  calcAnwersvalid
              "
            >
              {{ $t("common.buttons.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!--        Dialog Delete Notification Question-->
    <v-row justify="center">
      <v-dialog
        v-model="showDialogNotificationDeleteQuestion"
        persistent
        width="600px"
      >
        <v-card>
          <v-card-title class="headline secondary t-bw-secondary--text">{{
            $t("common.titles.information")
          }}</v-card-title>
          <v-card-text class="pa-6">
            <span style=" color: #e83b36" class="pb-6">{{
              $t("survey.notification.questionsAssociated")
            }}</span>
            <v-data-table
              :headers="headersExtraConfirmation"
              :items="deletedQuestionsNotifications.extraQuestions"
              :no-data-text="$t('common.ui.not_data_found')"
              :loading-text="$t('common.notification.loadData')"
              item-key="id"
              class="elevation-1 pt-3"
              :footer-props="{
                ...rowsPerPageItemsOptions,
                itemsPerPageText: $t('common.table.itemsPerPageText'),
                pageText: '{0}-{1} ' + $t('common.table.pageText') + ' {2}',
              }"
            >
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              small
              tile
              outlined
              class="ml-4"
              color="gray"
              @click="showDialogNotificationDeleteQuestion = false"
            >
              {{ $t("common.buttons.cancel") }}
            </v-btn>
            <v-btn
              small
              tile
              outlined
              class="mr-4"
              color="primary"
              @click="deleteWithConfirmation()"
            >
              {{ $t("common.buttons.confirm") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-speed-dial
      v-if="order"
      right
      bottom
      fixed
      absolute
      direction="top"
      transition="slide-y-reverse-transition"
      class="pb-12"
    >
      <template v-slot:activator>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="gray" dark fab small :loading="saveLoading">
              <v-icon @click="saveSurveyAction()">
                mdi-content-save
              </v-icon>
            </v-btn>
          </template>
          <span class="text-style">Save Template</span>
        </v-tooltip>
        
      </template>
    </v-speed-dial>

    <v-speed-dial
      v-model="fab"
      right
      bottom
      fixed
      absolute
      direction="top"
      transition="slide-y-reverse-transition"
    >
      <template v-slot:activator>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" v-model="fab" color="primary" dark fab small>
              <v-icon v-if="fab">
                mdi-close
              </v-icon>
              <v-icon v-else>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <span class="text-style">{{ $t('survey.buttons.actions') }}</span>
        </v-tooltip>
        
      </template>

      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" fab dark small color="primary" @click="publish">
            <v-icon>mdi-file-document-edit</v-icon>
          </v-btn>
        </template>
        <span class="text-style">{{ $t('survey.buttons.publish_template') }}</span>
      </v-tooltip>

      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" fab dark small color="success" @click="openAddSectionDialog(null)">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span class="text-style">{{ $t('survey.buttons.add_section') }}</span>
      </v-tooltip>

      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" fab dark small color="error" @click="back">
            <v-icon>mdi-arrow-left-bold</v-icon>
          </v-btn>
        </template>
        <span class="text-style">{{ $t('survey.buttons.back') }}</span>
      </v-tooltip>
    </v-speed-dial>

    <v-overlay class="ml-3" :value="!ready" absolute color="white">
      <v-progress-circular indeterminate size="64" color="primary" />
    </v-overlay>

    <v-expansion-panels v-model="panel" multiple>
      <draggable
        v-model="surveyModel.questionSectionList"
        class="full-width pa-4"
        @update="changeOrder()"
      >
        <v-expansion-panel
          v-for="sectionValues in surveyModel.questionSectionList"
          :key="sectionValues.orderCriteria"
          v-if="sectionValues.id > 0"
          :order="sectionValues.orderCriteria"
          width="100%"
        >
          <v-expansion-panel-header>
            <div class="pl-4">
              <span style=" font-size: 20px;" class="neuter--text">{{
                $t("survey.titles.section_title") + ": "
              }}</span>
              <span style=" font-size: 20px"
                ><strong>{{ sectionValues["name"] }}</strong></span
              >
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-toolbar flat>
              <v-toolbar-title>
                <span style=" font-size: 14px">{{
                  $t("survey.titles.punctuationSection")
                }}</span>
                <span style=" font-size: 16px" class="pl-2 font-weight-bold">{{
                  sectionPunctuation(sectionValues.id)
                }}</span>
                <span style=" font-size: 14px;" class="pl-2">{{
                  $t("survey.titles.punctuationSection1")
                }}</span>
                <span style=" font-size: 16px" class="pl-2 font-weight-bold">{{
                  sectionValues.punctuation
                }}</span>
                <span style=" font-size: 14px;" class="pl-2">{{
                  $t("survey.titles.punctuationSection2")
                }}</span>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <ButtonGroupComponent :buttons="getButtons(sectionValues)" />
            </v-toolbar>
            <v-data-table
              :headers="headers"
              :items="sectionValues.questions"
              :no-data-text="$t('common.ui.not_data_found')"
              :loading-text="$t('common.notification.loadData')"
              class="agTable pb-6"
              item-key="id"
              :id="sectionValues.id"
              sort-by="orderCriteria"
              :sort-desc="false"
              hide-default-footer
              disable-pagination
              :footer-props="{
                itemsPerPageText: $t('common.table.itemsPerPageText'),
                pageText: '{0}-{1} ' + $t('common.table.pageText') + ' {2}',
              }"
            >
              <template v-slot:item.extraQuestionFatherRef="{ item }">
                <v-icon
                  small
                  class="ml-6"
                  color="primary"
                  v-if="item.extraQuestionFatherRef"
                >
                  mdi-checkbox-marked-circle
                </v-icon>
              </template>
              <template v-slot:item.isRequired="{ item }">
                <v-icon
                  small
                  class="ml-8"
                  color="primary"
                  v-if="item.isRequired"
                >
                  mdi-checkbox-marked-circle
                </v-icon>
                <v-icon
                  small
                  class="ml-8"
                  color="error"
                  v-if="!item.isRequired"
                >
                  mdi-close-circle
                </v-icon>
              </template>
              <template v-slot:item.action="{ item }">
                <v-icon
                  color="neuter"
                  small
                  class="ml-2"
                  @click="editItem(item, sectionValues)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  color="neuter"
                  small
                  class="ml-2"
                  @click="deleteQuestionDialog(item, sectionValues)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </draggable>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import i18n from "@/plugins/i18n";
import { mapActions, mapGetters } from "vuex";
import PermisionService from "../../../services/PermisionService";
import _ from "lodash";
import draggable from "vuedraggable";
import Sortable from "sortablejs";
import LanguajeService from "../../../services/LanguajeService";
import sortList from "../../../services/OrderListService";
import ButtonGroupComponent from "@/components/common/ButtonGroup/ButtonGroupComponent.vue"

const PROFILE = JSON.parse(localStorage.getItem('profile'));

export default {
  name: "Survey",
  data() {
    return {
      buttonConfigs: [
        {
          text: this.$t("survey.buttons.delete_section"),
          action: () => this.deleteSectionDialog(this.sectionValues),
          props: {
            small: true,
            text: true,
            class: 'mr-2',
            color: 'error',
          },
        },
        {
          text: this.$t("survey.buttons.edit_section"),
          action: () => this.openAddSectionDialog(this.sectionValues),
          props: {
            small: true,
            text: true,
            class: 'mr-2',
            color: 'neuter',
          },
        },
        {
          text: this.$t("survey.buttons.add_question"),
          action: () => this.addQuestion(this.sectionValues),
          props: {
            small: true,
            text: true,
            color: 'primary',
          },
        },
      ],
      options: {},
      loadings: {
        products: false,
        seasons: false,
        equipments: false,
        operators: false,
        inputs: false
      },
      sameSession: false,
      disabledValue: {},
      isEditing: {},
      warning: {},
      alertWarning: false,
      loadingDelete: false,
      search: "",
      panel: [],
      order: false,
      ready: false,
      overlayQuestion: false,
      profile: {},
      fileEditChange: false,
      editedIndexSection: -1,
      editedIndexSurvey: -1,
      editedIndexQuestion: -1,
      saveLoading: false,
      valid: false,
      valid1: false,
      calcSectionvalid: false,
      calcAnwersvalid: false,
      limitSectionPunctuation: null,
      valid4: false,
      activeExtraQuestion: false,
      sectionSelected: {},
      surveyModel: {
        id: null,
        name: "",
        data: null,
        company: null,
        isTemplate: true,
        questionSectionList: [],
        surveyPunctuation: 0,
        surveyAnswerPunctuation: 0,
        surveyPercentPunctuation: 0,
      },
      editedItemSection: this.getQuestionSectionModel(),
      editedItemQuestion: this.getQuestionModel(),
      possibleAnswer: [],
      possibleListAnswer: [],
      possibleListObjectAnswer: [],
      deleteQuestionStatus: false,
      deleteSectionStatus: false,
      deletingQuestionItem: {},
      deletingSectionItem: {},
      showDialog: false,
      max: 250,
      showDialogAddEditQuestion: false,
      showAddSectionDialog: false,
      showAddSurveyDialog: false,
      showDialogNotificationDeleteQuestion: false,
      title: "Información",
      dialogText: "Añada un texto a este dialogo",
      rowsPerPageItemsOptions: {
        itemsPerPageOptions: [5],
      },
      //Actions buttons
      fab: false,
      notSelect: ["TEXT", "QR", "NUMBER", "LOTE", "DATA", "TIME", "DATATIME"],
      selects: [
        "PRODUCT",
        "EQUIPMENT",
        "OPERATOR",
        "MEASUREMENTUNIT",
        "SEASON",
        "INPUT",
        "LIST"
      ],
      selectsSimples: [
        "PRODUCTSIMPLE",
        "EQUIPMENTSIMPLE",
        "OPERATORSIMPLE",
        "MEASUREMENTUNITSIMPLE",
        "SEASONSIMPLE",
        "INPUTSIMPLE",
        "LISTSIMPLE"
      ],
      defaultFilters: {
        companyId: PROFILE.company_id,
        search: "",
        pageableDTO: {
          page: 0,
          size: 10,
          sortBy: 'id',
          direction: 'ASC',
        }
      },
    };
  },
  components: {
    ButtonGroupComponent,
    draggable,
  },

  computed: {
    ...mapGetters({
      structureType: "details/detailsLocalEnum",
      authoritiesList: "authority/authorities",
      locationList: "storage/allStoragesByCompany",
      requireAndMaxRules: "general/requireAndMaxRules",
      requireRules: "general/requireRules",
      products: "product/products",
      totalProducts: "product/totalFromServer",
      operators: "operator/operators",
      totalOperators: "operator/totalFromServer",
      equipments: "equipment/equipments",
      totalEquipments: "equipment/totalFromServer",
      seasons: "season/seasons",
      totalSeason: "season/totalFromServer",
      allUnits: "units/allObjectUnits",
      inputs: "input/inputs",
      totalInputs: "input/totalFromServer",
      surveyEdit: "survey/surveyEdit",
      error: "survey/error",
      requireSize: "general/requireSize",
      deletedQuestionsNotifications: "survey/deletedQuestionsNotifications",
      languageStructure: "general/languageStructure",
      onlyDecimalFormat: "general/onlyDecimalFormat",
      onlyNumberFormat: "general/onlyNumberFormat",
      compareMax: "general/compareMax"
    }),
    /*products: {
      get() {
        return this.$store.getters['product/products']
      },
      set(val) {
        this.$store.commit('product/SET_PRODUCT_LIST', val)
      }
    },*/
    headers: () => [
      {
        text: i18n.t("survey.fields.question"),
        value: "question",
        width: "60%",
        sortable: false,
      },
      {
        text: i18n.t("survey.fields.associating"),
        value: "extraQuestionFatherRef",
        width: "10%",
        sortable: false,
      },
      {
        text: i18n.t("survey.fields.note"),
        value: "questionPunctuation",
        width: "10%",
        sortable: false,
      },
      {
        text: i18n.t("survey.fields.isRequired"),
        value: "isRequired",
        width: "10%",
        sortable: false,
      },
      {
        text: i18n.t("common.fields.action"),
        value: "action",
        sortable: false,
        align: "end",
        width: "10%",
      },
    ],
    headersExtra: () => [
      {
        text: i18n.t("survey.fields.questionExtra"),
        value: "question",
        width: "100%",
        sortable: false,
      },
    ],
    headersExtraConfirmation: () => [
      {
        text: i18n.t("survey.fields.associated"),
        value: "question",
        width: "100%",
        sortable: false,
      },
    ],
    headersProducts: () => [
      { text: i18n.t("product.fields.name"), value: "product_name", width: 200 },
      { text: i18n.t("survey.fields.note"), value: "anwerPuntuation", width: 100 },
    ],
    headersEquipments: () => [
      { text: i18n.t("equipment.fields.name"), value: "equipment_name", width: 200 },
      { text: i18n.t("survey.fields.note"), value: "anwerPuntuation", width: 100 },
    ],
    headersOperators: () => [
      { text: i18n.t("operator.fields.name"), value: "operator_name", width: 200 },
      { text: i18n.t("survey.fields.note"), value: "anwerPuntuation", width: 100 },
    ],
    headersInputs: () => [
      { text: i18n.t("input.fields.name"), value: "input_name", width: 200 },
      {
        text: i18n.t("survey.fields.note"),
        value: "anwerPuntuation",
        width: 100,
      },
    ],
    headersMeasurements: () => [
      { text: i18n.t("measurementUnit.fields.name"), value: "unit_name", width: 200 },
      { text: i18n.t("survey.fields.note"), value: "anwerPuntuation", width: 100 },
    ],
    headersSeassons: () => [
      { text: i18n.t("season.fields.name"), value: "season_name", width: 200 },
      { text: i18n.t("survey.fields.note"), value: "anwerPuntuation", width: 100 },
    ],
    headersList: () => [
      { text: i18n.t("survey.fields.answer"), value: "name", width: 200 },
      { text: i18n.t("survey.fields.note"), value: "anwerPuntuation", width: 100 },
    ],
    // Form condition title
    formTitle() {
      return this.editedIndexSurvey >= 0
        ? i18n.t("survey.titles.edit_title")
        : i18n.t("survey.titles.add_title");
    },
    formDialogTitle() {
      return this.editedIndexSurvey >= 0
        ? i18n.t("survey.titles.edit_title")
        : i18n.t("survey.titles.new_survey");
    },
    formQuestionDialogTitle() {
      return this.editedIndexQuestion >= 0
        ? i18n.t("survey.titles.edit_question_title")
        : i18n.t("survey.titles.add_question_title");
    },
    surveyPunctuation() {
      let punctuation = 0;
      _.forEach(this.surveyModel.questionSectionList, (section) => {
        punctuation += section.punctuation;
      });
      return punctuation;
    },
    getValidSectionCalc() {
      return this.calcSectionvalid;
    },
  },

  watch: {
    options: {
      async handler() {
        this.optionsTable = this.options
        await this.searchText()
      },
      deep: true,
    },
    warning() {
      let count = 0;
      _.forEach(this.warning, (item) => {
        if (item === true) {
          count++;
        }
      });
      this.alertWarning = count > 0;
    },
  },

  async created() {
    this.config = JSON.parse(localStorage.getItem('config'))
    this.sameSession = JSON.parse(localStorage.getItem('sameSession'))
    console.log(this.sameSession);

    await this.initialize();
    this.panel = [
      ...Array(this.surveyModel.questionSectionList.length).keys(),
    ].map((k, i) => i);
  },

  methods: {
    ...mapActions({
      fetchListProduct: "product/fetchListProduct",
      fetchListOperator: "operator/fetchListOperator",
      fetchListEquipment: "equipment/fetchListEquipment",
      fetchListInputs: "input/fetchListInputs",
      fetchListSeasons: "season/fetchListSeasons",
      saveSurvey: "survey/saveSurvey",
      saveSection: "survey/saveSection",
      saveQuestion: "survey/saveQuestion",
      deleteQuestion: "survey/deleteQuestion",
      deleteQuestionWithDependents: "survey/deleteQuestionWithDependents",
      deleteQuestionSection: "survey/deleteQuestionSection",
      fetchSurveyById: "survey/fetchSurveyById",
      change_state: "survey/change_state",
      fetchListAuthority: "authority/fetchListAuthority",
      fetchAllUnits: "units/fetchAllUnits",
      errorOFF: "survey/errorOFF",
      setProductList: "product/setProductList",
      setInputsList: "input/setInputsList",
      setUnitsList: "units/setUnitsList",
      setSeasonList: "season/setSeasonList",
      setEquipmentList: "equipment/setEquipmentList",
      setOperatorList: "operator/setOperatorList",
    }),

    getButtons(sectionValues) {
      return [
        {
          type: "button",
          text: this.$t("survey.buttons.delete_section"),
          action: () => this.deleteSectionDialog(sectionValues),
          props: {
            small: true,
            elevation: 0,
            class: 'mr-1',
            color: 'gray',
          },
          icon: 'mdi-delete-outline'
        },
        {
          type: "button",
          text: this.$t("survey.buttons.edit_section"),
          action: () => this.openAddSectionDialog(sectionValues),
          props: {
            small: true,
            elevation: 0,
            class: 'mr-1',
            color: 'gray',
          },
          icon: 'mdi-square-edit-outline'
        },
        {
          type: "button",
          text: this.$t("survey.buttons.add_question"),
          action: () => this.addQuestion(sectionValues),
          props: {
            small: true,
            elevation: 0,
            color: 'gray'
          },
          icon: 'mdi-plus'
        },
      ];
    },

    updateSameSession(status) {
      this.sameSession = status;
      localStorage.setItem("sameSession", JSON.stringify(status))
    },

    async searchAll() {
      let filters = {
        companyId: null,
        search: null,
        pageableDTO: {
          page: 0,
          size: 10,
          sortBy: 'id',
          direction: 'DESC',
        }
      }

      filters.companyId = this.profile.company_id

      if (this.editedItemQuestion.questionStructureType) {
        const { action, nomenclator } = this.setRequireData(this.editedItemQuestion.questionStructureType)

        await this[action]([filters, this.$toast]).finally(() => {
          _.forEach(this[nomenclator], (p, k) => {
            _.forEach(this.possibleAnswer, (a) => {
              if (p.objectRelId === a.objectRelId)
                this.$set(this[nomenclator][k], 'anwerPuntuation', a.anwerPuntuation)
            })
          })
        })
      }
    },

    async searchForText() {
      this.options.page = 1
      await this.searchText()
    },

    async searchText() {
      let filters = {
        companyId: null,
        search: null,
        pageableDTO: {
          page: null,
          size: null,
          sortBy: 'id',
          direction: 'DESC'
        }
      }

      filters.companyId = this.profile.company_id

      const direction =
        this.options.sortDesc.length === 0 || this.options.sortDesc[0]
          ? 'asc'
          : 'desc'
      const sort =
        this.options.sortBy.length === 0
          ? 'id'
          : _.filter(this.headers, { value: this.options.sortBy[0] })[0].value
      filters.pageableDTO = {
        page: this.options.page - 1,
        size: this.options.itemsPerPage,
        sortBy: sort,
        direction: direction,
      }

      if (this.search && this.search !== '')
        filters.search = this.search

      if (this.editedItemQuestion.questionStructureType){
        const { action, nomenclator } = this.setRequireData(this.editedItemQuestion.questionStructureType)

        await this[action]([filters, this.$toast]).finally(() => {
          _.forEach(this[nomenclator], (p, k) => {
            _.forEach(this.possibleAnswer, (a) => {
              if (p.objectRelId === a.objectRelId)
                this.$set(this[nomenclator][k], 'anwerPuntuation', a.anwerPuntuation)
            })
          })
        })
      }
    },

    setRequireData(questionStructureType) {
      let data = {
        action: "",
        nomenclator: ""
      }
      switch (questionStructureType) {
        case "PRODUCT":
        case "PRODUCTSIMPLE":
          data = {
            action: "fetchListProduct",
            nomenclator: "products"
          }
          break;
        case "EQUIPMENT":
        case "EQUIPMENTSIMPLE":
          data = {
            action: "fetchListEquipment",
            nomenclator: "equipments"
          }
          break;
        case "OPERATOR":
        case "OPERATORSIMPLE":
          data = {
            action: "fetchListOperator",
            nomenclator: "operators"
          }
          break;
        case "SEASON":
        case "SEASONSIMPLE":
          data = {
            action: "fetchListSeasons",
            nomenclator: "seasons"
          }
          break;
        case "INPUT":
        case "INPUTSIMPLE":
          data = {
            action: "fetchListInputs",
            nomenclator: "inputs"
          }
          break;
      }

      return data;
    },

    setExtraQuestionFields() {
      this.$set(this.editedItemQuestion, 'extra', this.activeExtraQuestion)
      if (!this.editedItemQuestion.extra) {
        this.$set(this.editedItemQuestion, 'extraQuestionFatherRef', [])
        this.$set(this.editedItemQuestion, 'condicionalAnswer', null)
      }
    },

    removeElementFormList(index) {
      this.possibleListAnswer.splice(index, 1);
      this.possibleListObjectAnswer.splice(index, 1);
      this.possibleAnswer.splice(index, 1);
      this.alertWarning = false
    },

    selectedListItem(select) {
      if (!select.value) {
        this.$set(select.item, "anwerPuntuation", "");
        this.filterFloat(select.item.anwerPuntuation, select.item.name);
      } else {
        this.disabledValue[select.item.objectRelId] = true;
      }
      this.getValidPossibleAnswers();
    },

    selectedItem(select) {
      if (!select.value) {
        this.$set(select.item, "anwerPuntuation", "");
        this.filterFloat(select.item.anwerPuntuation, select.item.objectRelId);
        this.disabledValue[select.item.objectRelId] = false;
      } else {
        this.disabledValue[select.item.objectRelId] = true;
      }
      this.getValidPossibleAnswers();
    },

    selectItems(all) {
      if (all.value) {
        _.forEach(all.items, (item) => {
          this.disabledValue[item.objectRelId] = true;
        });
      } else {
        _.forEach(all.items, (item) => {
          this.$set(item, "anwerPuntuation", "");
          this.filterFloat(item.anwerPuntuation, item.objectRelId);
          this.disabledValue[item.objectRelId] = false;
        });
      }
    },

    setPunctuationOnChange(item) {
      _.forEach(this.possibleAnswer, (a, k) => {
        if (item.id === a.id) {
          this.$set(this.possibleAnswer[k], 'anwerPuntuation', item.anwerPuntuation)
        }
      })
    },

    filterFloat(__val__, name, item, object) {
      let lastCharacter = null;

      if (__val__ === null)
        return

      if (__val__ !== undefined) {
        lastCharacter = __val__.slice(__val__.length - 1, __val__.length);
      }

      if (lastCharacter === ".") {
        this.warning[name] = true;
      } else {
        let preg = /^([0-9]+\.?[0-9]{0,2})$/;
        this.warning[name] = !(preg.test(__val__) === true || __val__ === "");
      }

      let cant = 0;
      _.forEach(this.warning, (item) => {
        if (item === true) {
          cant++;
        }
      });
      this.alertWarning = cant > 0;

      if (object !== undefined && !this.warning[name]) {
        this.saveAnswerPuntuation(item, object);
        this.setPunctuationOnChange(item)
      }
    },

    cancelPunctuation(item) {
      this.filterFloat(item.anwerPuntuation, item.name);
    },

    cancelNomenclator(item) {
      this.filterFloat(item.anwerPuntuation, item.objectRelId);
    },

    translate(key, uppercase) {
      return LanguajeService.getKey3(key);
    },

    async initialize() {
      this.ready = false;
      let surveyId = JSON.parse(localStorage.getItem("survey"));
      if (surveyId) {
        this.editedIndexSurvey = surveyId;
      } else {
        this.editedIndexSurvey = -1;
        this.showAddSurveyDialog =
          this.surveyModel.name === "" || this.surveyModel.name === null;
      }
      try {
        await this.loadDependencies();
      } finally {
        if (this.editedIndexSurvey > 0) {
          this.surveyModel = Object.assign({}, this.surveyEdit);
          localStorage.setItem("survey", JSON.stringify(this.surveyModel.id));
          if (
            this.surveyModel.questionSectionList &&
            this.surveyModel.questionSectionList.length === 0
          ) {
            this.showAddSectionDialog = true;
          }
        }
        this.ready = true;
        setTimeout(() => {
          this.addDraggable();
        }, 300);
      }
    },

    async loadDependencies() {
      const promises = [];
      // add here a list of promises;
      this.profile = JSON.parse(localStorage.getItem("profile"));
      if (this.editedIndexSurvey > 0) {
        promises.push(
          this.fetchSurveyById([this.editedIndexSurvey, this.$toast])
        );
      }
     /* promises.push(this.fetchListProduct([{
        companyId: this.profile.company_id,
        search: "",
        pageableDTO: {
          page: 0,
          size: 5,
          sortBy: 'id',
          direction: 'ASC',
        }
      }, this.$toast]));*/
      /*const pageable = {
        page: 0,
        size: 1000,
        sortBy: 'id',
        direction: 'ASC',
      }*/

      /*promises.push(this.fetchListOperator([{ ...this.defaultFilters, pageableDTO: pageable }, this.$toast]));
      promises.push(this.fetchListEquipment([{ ...this.defaultFilters, pageableDTO: pageable }, this.$toast]));
      promises.push(this.fetchListInputs([{ ...this.defaultFilters, pageableDTO: pageable }, this.$toast]));
      promises.push(this.fetchListSeasons([{ ...this.defaultFilters, pageableDTO: pageable }, this.$toast]));*/
      promises.push(this.fetchAllUnits(this.$toast));
      return await Promise.all(promises);
    },

    addDraggable() {
      let cards = document.querySelectorAll(".v-card");
      let allTableSection = document.querySelectorAll(".v-data-table tbody");
      const _self = this;
      _.forEach(allTableSection, (sectionElement) => {
        Sortable.create(sectionElement, {
          onEnd({ newIndex, oldIndex }) {
                            let section = _.find(_self.surveyModel.questionSectionList, {id: parseFloat(sectionElement.parentNode.parentNode.parentNode.id)});
                            let questions = Object.assign([], section.questions);
            const rowSelected = questions.splice(oldIndex, 1)[0];
            questions.splice(newIndex, 0, rowSelected);
            questions = _.map(questions, (item, key) => {
              return {
                ...item,
                orderCriteria: key + 1,
              };
            });
            _self.changeOrder(section, questions);
            console.log("order End");
          },
        });
      });
    },

    changeOrder(targetSection, targetQuestions) {
      this.order = true;
      this.surveyModel.questionSectionList = _.map(
        this.surveyModel.questionSectionList,
        (section, indexSection) => {
          return {
            ...section,
            orderCriteria: indexSection + 1,
            questions:
              targetSection && targetSection.id === section.id
                ? targetQuestions
                : _.map(section.questions, (question, index) => {
                    return {
                      ...question,
                      orderCriteria: index + 1,
                    };
                  }),
          };
        }
      );
    },

    check(permisions) {
      return PermisionService.check(permisions);
    },

    dialogShow(params) {
      this.title = params.title;
      this.dialogText = params.dialogText;
      this.showDialog = true;
    },

    dialogClose() {
      this.showDialog = false;
    },

    // Accion de los modales de Borrar para secciones y preguntas
    async deleteItem() {
      this.loadingDelete = true;
      if (this.deleteSectionStatus === true) {
        this.deleteSectionStatus = false;
        let index = this.surveyModel.questionSectionList.indexOf(
          this.deletingSectionItem
        );
        await this.deleteQuestionSection([
          this.deletingSectionItem.id,
          this.$toast,
        ]).finally(async () => {
          await this.surveyEditRefresh();
        });
      } else if (this.deleteQuestionStatus === true) {
        this.deleteQuestionStatus = false;
        await this.deleteQuestion([
          this.deletingQuestionItem.id,
          this.$toast,
        ]).finally(async () => {
          if (!this.deletedQuestionsNotifications.extraQuestions) {
            await this.surveyEditRefresh();
          } else {
            this.showDialogNotificationDeleteQuestion = true;
          }
        });
      }
      this.loadingDelete = false;
      this.dialogClose();
    },
    async deleteWithConfirmation() {
      this.showDialogNotificationDeleteQuestion = false;
      await this.deleteQuestionWithDependents([
        this.deletingQuestionItem.id,
        this.$toast,
      ]).finally(async () => {
        await this.surveyEditRefresh();
      });
    },

    // SURVEY
    async saveSurveyAction() {
      this.saveLoading = true;
      this.surveyModel.company = { id: this.profile.company_id };
      await this.saveSurvey([
        {
          ...this.surveyModel, 
          sameSession: this.sameSession
        },
        this.editedIndexSurvey,
        null,
        null,
        this.$toast
      ]).then(async () => {
        if (!this.error) {
          this.surveyModel.id = this.surveyEdit.id;
          this.editedIndexSurvey = this.surveyModel.id;
          this.showAddSurveyDialog = false;

          this.updateSameSession(true);

          await this.surveyEditRefresh();
        } else {
          this.errorOFF();
          await this.$router.push({ name: "survey" });
        }

        this.saveLoading = false;
      });
    },

    cancelSurvey() {
      if (this.editedIndexSurvey < 0) {
        this.$refs.form5.reset();
        this.$router.push({ name: "survey" });
      } else {
        this.showAddSurveyDialog = false;
      }
    },

    async surveyEditRefresh() {
      if (this.surveyModel.id) {
        await this.fetchSurveyById([this.surveyModel.id, this.$toast]).finally(
          () => {
            this.surveyModel = {};
            this.surveyModel = Object.assign({}, this.surveyEdit);
            if (
              this.surveyModel.questionSectionList &&
              this.surveyModel.questionSectionList.length === 0
            ) {
              this.showAddSectionDialog = true;
            }
            localStorage.setItem("survey", JSON.stringify(this.surveyModel.id));
          }
        );
      }
      this.panel.push(this.panel.length);
      this.order = false;
    },

    // SECTION QUESTIONS
    limitSection() {
      let calc = 0;
      _.forEach(this.editedItemSection.questions, (question) => {
        calc += question.questionPunctuation;
        calc += _.sumBy(question.extraQuestions, "questionPunctuation");
      });
      this.calcSectionvalid =
        calc <= parseFloat(this.editedItemSection.punctuation);
      if (this.calcSectionvalid === null) {
        this.calcSectionvalid = 0;
      }
      return calc;
    },

    getSectionErrorText() {
      return (
        i18n.t("survey.titles.punctuationSection") +
        this.limitSection() +
        " > " +
        this.editedItemSection.punctuation +
        " " +
        i18n.t("survey.titles.punctuationSection2")
      );
    },

    getQuestionSectionModel() {
      return {
        name: null,
        questions: [],
        responsables: [],
        locations: [],
        punctuation: null,
        orderCriteria: 0,
      };
    },

    sectionPunctuation(sectionId) {
      let calc = 0;
      _.forEach(
        _.find(this.surveyModel.questionSectionList, { id: sectionId })
          .questions,
        (question) => {
          calc += question.questionPunctuation;
        }
      );
      return parseFloat(calc);
    },

    openAddSectionDialog(section) {
      this.editedItemSection = section
        ? Object.assign({}, section)
        : this.getQuestionSectionModel();
      this.editedIndexSection = section ? this.editedItemSection.id : -1;
      this.showAddSectionDialog = true;
    },

    cancelAddSection() {
      this.showAddSectionDialog = false;
      this.$refs.form4.reset();
      this.editedItemSection = this.getQuestionSectionModel();
    },

    deleteSectionDialog(sectionValues) {
      this.deleteSectionStatus = true;
      this.deletingSectionItem = sectionValues;
      this.dialogShow({
        title: i18n.t("common.titles.alert"),
        dialogText: i18n.t("common.notification.deleteItems"),
      });
    },

    saveSectionAction() {
      let item = _.cloneDeep(this.editedItemSection);

      item.company = { id: this.profile.company_id };
      if (this.surveyModel.hasOwnProperty("id"))
        item.surveyId = this.surveyModel.id;
      this.showAddSectionDialog = false;
      item.orderCriteria =
        this.editedIndexSection >= 0
          ? item.orderCriteria
          : this.surveyModel.questionSectionList.length > 0
          ? _.maxBy(this.surveyModel.questionSectionList, "orderCriteria")
              .orderCriteria + 1
          : 1;
      item.punctuation = parseFloat(item.punctuation);
      this.saveSection([
        {
          ...item, 
          sameSession: this.sameSession
        }, this.editedIndexSection, this.$toast])
        .then((isOK) => {
          if (isOK)
            this.updateSameSession(true);
        })
        .finally(() => {
          this.surveyEditRefresh();
        }
      );
      this.cancelAddSection();
    },

    // QUESTIONS
    getQuestions() {
      let questions = [];
      _.forEach(this.surveyModel.questionSectionList, (section) => {
        _.forEach(section.questions, (question) => {
          if (!question.extraQuestionFatherRef) {
            if (this.editedItemQuestion.id !== question.id) {
              questions.push(question);
            }
          }
        });
      });
      return questions;
    },

    async editItem(item, sectionValue) {
      this.changePosibleAnwers(item.questionStructureType);
      this.fileEditChange = false;
      this.editedItemQuestion = this.getQuestionModel();
      this.editedIndexQuestion = sectionValue.questions.indexOf(item);
      this.sectionSelected = _.cloneDeep(sectionValue);
      this.editedItemQuestion = _.cloneDeep(item);
      if (this.editedItemQuestion.extraQuestionFatherRef) {
        this.activeExtraQuestion = !!this.editedItemQuestion.extraQuestionFatherRef;
        const father = this.editedItemQuestion.extraQuestionFatherRef;
        this.editedItemQuestion.extraQuestionFatherRef = [father];
      } else {
        this.$set(this.editedItemQuestion, 'extraQuestionFatherRef', [])
      }
      if (!this.editedItemQuestion.questionHelp) {
        this.editedItemQuestion.questionHelp = { text: "" };
      }
      if (this.editedItemQuestion.possibleAnswer.length > 0) {
        this.possibleAnswer = _.map(
          this.editedItemQuestion.possibleAnswer,
          (possibleAnswer) => {
            this.possibleListAnswer.push(possibleAnswer.answer);
            this.disabledValue[possibleAnswer.objectRelId] = true;

            /*let product = _.find(this.products, { objectRelId: possibleAnswer.objectRelId })

            if (product && product.length > 0) {
              this.$set(product, 'anwerPuntuation', possibleAnswer.punctuation)
            }*/

            return {
              ...possibleAnswer,
              anwerPuntuation: possibleAnswer.punctuation,
              name: possibleAnswer.answer,
            };
          }
        );

        if (
          this.editedItemQuestion.questionStructureType === "LIST" ||
          this.editedItemQuestion.questionStructureType === "LISTSIMPLE"
        ) {
          _.forEach(this.possibleListAnswer, (object, key) => {
            const posibleAnsW = _.find(this.possibleAnswer, { name: object });
            posibleAnsW.id = key + 1;
            this.possibleListObjectAnswer.push(posibleAnsW);
          });
        }
      }
      this.editedItemQuestion.questionSectionId = this.sectionSelected.id;
      this.showDialogAddEditQuestion = true;
      // await this.searchAll();
      // this.setObjectStructurePuntuation();
    },

    setObjectStructurePuntuation() {
      switch (this.editedItemQuestion.questionStructureType) {
        case "OPERATORSIMPLE":
        case "OPERATOR":
          this.setOperatorList(this.getMapObjct(this.operators));
          break;
        case "EQUIPMENTSIMPLE":
        case "EQUIPMENT":
          this.setEquipmentList(this.getMapObjct(this.equipments));
          break;
        case "SEASONSIMPLE":
        case "SEASON":
          this.setSeasonList(this.getMapObjct(this.seasons));
          break;
        case "MEASUREMENTUNITSIMPLE":
        case "MEASUREMENTUNIT":
          this.setUnitsList(this.getMapObjct(this.allUnits));
          break;
        case "INPUTSIMPLE":
        case "INPUT":
          this.setInputsList(this.getMapObjct(this.inputs));
          break;
        case "PRODUCTSIMPLE":
        case "PRODUCT":
          this.setProductList(this.getMapObjct(this.products));
          break;
      }
    },

    getMapObjct(targetList) {
      return _.map(targetList, (item) => {
        const found = _.find(this.possibleAnswer, { objectRelId: item.id });
        return {
          ...item,
          anwerPuntuation: found ? found.anwerPuntuation : null,
          objectRelId: item.id,
        };
      });
    },

    getObjectStructureToSave(questionStructureType, objectRelId) {
      let object = null;
      switch (questionStructureType) {
        case "OPERATORSIMPLE":
        case "OPERATOR":
          object = this.getMapObjectToSave(this.operators, objectRelId);
          return object ? object.languageKey : "";
        case "EQUIPMENTSIMPLE":
        case "EQUIPMENT":
          object = this.getMapObjectToSave(this.equipments, objectRelId);
          return object ? object.language_key : "";
        case "SEASONSIMPLE":
        case "SEASON":
          object = this.getMapObjectToSave(this.seasons, objectRelId);
          return object ? object.languageKey : "";
        case "MEASUREMENTUNITSIMPLE":
        case "MEASUREMENTUNIT":
          object = this.getMapObjectToSave(this.allUnits, objectRelId);
          return object ? object.languageKey : "";
        case "INPUTSIMPLE":
        case "INPUT":
          object = this.getMapObjectToSave(this.inputs, objectRelId);
          return object ? object.languageKey : "";
        case "PRODUCTSIMPLE":
        case "PRODUCT":
          object = this.getMapObjectToSave(this.possibleAnswer, objectRelId);
          return object ? object.language_key : "";
      }
    },

    getMapObjectToSave(objects, id) {
      return _.find(objects, { id: id });
    },

    getQuestionModel() {
      return {
        question: null,
        questionStructureType: null,
        questionHelp: {},
        answer: null,
        anwerPuntuation: null,
        isRequired: false,
        isInformative: false,
        questionPunctuation: null,
        possibleAnswer: [],
        questionSectionId: null,
        orderCriteria: 0,
        company: null,
        // extra question
        extra: false,
        extraQuestionFatherRef: [],
        extraQuestionFather: null,
        extraQuestions: [],
        condicionalAnswer: null,
      };
    },

    cancelAddEditDialog() {
      this.warning = {};
      this.alertWarning = false;
      this.calcAnwersvalid = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.showDialogAddEditQuestion = false;
      this.editedItemQuestion = this.getQuestionModel();
      this.editedIndexQuestion = -1;
      this.limitSectionPunctuation = null;
      this.possibleAnswer = [];
      this.possibleListAnswer = [];
      this.possibleListObjectAnswer = [];
    },

    addQuestion(sectionValues) {
      this.sectionSelected = Object.assign({}, sectionValues);
      this.showDialogAddEditQuestion = true;
    },

    async saveQuestionAction() {
      this.overlayQuestion = true;
      let question = _.cloneDeep(this.editedItemQuestion);
      if (
        !_.keys(question.questionHelp).length > 0 ||
        !question.questionHelp.text ||
        question.questionHelp.text === ""
      ) {
        question.questionHelp = null;
      }
      question.possibleAnswer = [];
      if (this.possibleAnswer.length > 0) {
        _.forEach(this.possibleAnswer, (possibleAnswer) => {
          if (
            (question.questionStructureType === "LIST" ||
              question.questionStructureType === "LISTSIMPLE") &&
            !possibleAnswer.answer_name
          ) {
            possibleAnswer.answer_name = possibleAnswer.name;
          }

          question.possibleAnswer.push({
            id: this.editedIndexQuestion >= 0 ? possibleAnswer.id : null,
            objectRelId:
              question.questionStructureType !== "LIST" &&
              question.questionStructureType !== "LISTSIMPLE"
                ? possibleAnswer.objectRelId
                : 0,
            answer:
              question.questionStructureType === "LIST" ||
              question.questionStructureType === "LISTSIMPLE"
                ? LanguajeService.setKey3(
                    possibleAnswer.answer_name,
                    possibleAnswer.name,
                    this.editedIndexQuestion >= 0 &&
                      typeof possibleAnswer.name === "object"
                      ? null
                      : this.languageStructure
                  )
                : possibleAnswer.language_key
                  ? possibleAnswer.language_key
                  : possibleAnswer.languageKey,
            punctuation:
              possibleAnswer.anwerPuntuation === null
                ? 0
                : parseFloat(possibleAnswer.anwerPuntuation),
          });
        });
      }
      question.isInformative = question.questionPunctuation <= 0;
      question.questionPunctuation = question.questionPunctuation
        ? question.questionPunctuation
        : 0;
      question.questionPunctuation = parseFloat(question.questionPunctuation);
      question.extraQuestionFatherRef = question.extraQuestionFatherRef
        ? question.extraQuestionFatherRef[0]
        : question.extraQuestionFatherRef;
      question.company = { id: this.profile.company_id };
      question.questionSectionId = this.sectionSelected.id;
      question.orderCriteria =
        this.editedIndexQuestion >= 0
          ? question.orderCriteria
          : this.sectionSelected.questions.length > 0
          ? _.maxBy(this.sectionSelected.questions, "orderCriteria")
              .orderCriteria + 1
          : 1;

      await this.saveQuestion([
        {
          ...question, 
          sameSession: this.sameSession
        },
        this.editedIndexQuestion,
        this.$toast,
      ]).then((isOK) => {
        if (isOK)
          this.updateSameSession(true);
      });

      await this.surveyEditRefresh().finally(() => {
        this.overlayQuestion = false;
      });
      this.cancelAddEditDialog();
    },

    deleteQuestionDialog(item, sectionValues) {
      this.deleteQuestionStatus = true;
      this.deletingQuestionItem = item;
      this.deletingSectionItem = sectionValues;
      this.dialogShow({
        title: i18n.t("common.titles.alert"),
        dialogText: i18n.t("common.notification.deleteItems"),
      });
    },

    changedFile() {
      if (this.editedIndexQuestion >= 0) {
        this.fileEditChange = true;
      }
    },

    // Add new Question
    addQuestionItem(sectionId) {
      localStorage.removeItem("section");
      localStorage.setItem("section", JSON.stringify(sectionId));
      this.$router.push({ name: "questionAdd" });
    },

    // Edit new Question
    editQuestionItem(item) {
      localStorage.setItem("question", JSON.stringify(item));
      this.$router.push({ name: "questionAdd" });
    },

    // ANSWER PUNTUATION
    saveAnswerPuntuation(item, objects) {
      if (objects) {
        let found = false;
        _.forEach(this.possibleAnswer, (object, key) => {
          if (object.objectRelId === item.id) {
            this.possibleAnswer[key].anwerPuntuation = item.anwerPuntuation;
            found = true;
          }
        });
        if (!found) {
          this.possibleAnswer.push(item);
        }
      } else {
        let found = false;
        _.forEach(this.possibleAnswer, (object, key) => {
          if (object.name === item.name) {
            this.possibleAnswer[key] = item;
            found = true;
          }
        });
        if (!found) {
          this.possibleAnswer.push({ name: item, anwerPuntuation: null });
        }
      }
      this.getValidPossibleAnswers();
    },

    getValidPossibleAnswers() {
      if (this.editedItemQuestion.questionPunctuation) {
        const outLimitPunctuation = _.filter(this.possibleAnswer, (answer) => {
          return (
            parseFloat(answer.anwerPuntuation) >
            parseFloat(this.editedItemQuestion.questionPunctuation)
          );
        });
        this.calcAnwersvalid =
          outLimitPunctuation && outLimitPunctuation.length > 0;
      }
    },

    clearInput(elements, nomenclator) {
      _.forEach(elements, (element, k) => {
        if (this.isKeyExists(element, "anwerPuntuation")) {
          this.$set(this[nomenclator][k], "anwerPuntuation", "");
          this.$set(this.disabledValue, element.objectRelId, false)
        }
      });
    },

    isKeyExists(obj, key) {
      if (obj[key] === undefined) {
        return false;
      } else {
        return true;
      }
    },

    changePosibleAnwers(property) {
      this.search = ""

      switch (property) {
        case "INPUTSIMPLE":
        case "INPUT":
          this.clearInput(this.inputs, 'inputs');
          break;
        case "OPERATORSIMPLE":
        case "OPERATOR":
          this.clearInput(this.operators, 'operators');
          break;
        case "EQUIPMENTSIMPLE":
        case "EQUIPMENT":
          this.clearInput(this.equipments, 'equipments');
          break;
        case "SEASONSIMPLE":
        case "SEASON":
          this.clearInput(this.seasons, 'seasons');
          break;
        case "MEASUREMENTUNITSIMPLE":
        case "MEASUREMENTUNIT":
          this.clearInput(this.allUnits, 'allUnits');
          break;
        case "PRODUCTSIMPLE":
        case "PRODUCT":
          this.clearInput(this.products, 'products');
          break;
      }
      this.possibleAnswer = [];
      this.possibleListAnswer = [];
      this.possibleListObjectAnswer = [];
      _.forEach(this.warning, (item, index) => {
        this.warning[index] = false;
      });
      this.alertWarning = false;
      this.calcAnwersvalid = false;
    },

    changeListStructure(event) {
      let newPossibleAnswer = [];
      if(typeof event[event.length - 1] === 'string') {
        this.$set(event, event.length - 1, LanguajeService.setKey3(event[event.length - 1], event[event.length - 1], this.languageStructure))
      }
      _.forEach(this.possibleListAnswer, (object, key) => {
        if (typeof object === 'string') {
          object = object.replaceAll('"', '')
          const possibleAnsW = _.find(this.possibleAnswer, { name: object });

          if (possibleAnsW) {
            newPossibleAnswer.push(possibleAnsW);
          } else {
            newPossibleAnswer.push({
              name: object,
              anwerPuntuation: null ,
              answer: object,
              answer_name: event[event.length - 1][0].label,
              id: (this.possibleAnswer && this.possibleAnswer.length > 0 ) ? this.possibleAnswer[this.possibleAnswer.length -1].id + 1 : 1,
              objectRelId: 0 });
          }
        } else {
          _.forEach(object, (o) => {
            o.label = o.label.replaceAll('"', '')
          })
          const possibleAnsW = _.find(this.possibleAnswer, { name: object });

          if (possibleAnsW) {
            newPossibleAnswer.push(possibleAnsW);
          } else {
            newPossibleAnswer.push({
              name: object,
              anwerPuntuation: null ,
              answer: object,
              answer_name: event[event.length - 1][0].label,
              id: (this.possibleAnswer && this.possibleAnswer.length > 0 ) ? this.possibleAnswer[this.possibleAnswer.length -1].id + 1 : 1,
              objectRelId: 0
            });
          }
        }
      });

      let possibleAnswers = []
      _.forEach(newPossibleAnswer, (answer) => {
        if(typeof answer.name === 'string') {
          possibleAnswers.push(answer.name.replaceAll('"', ''))
        } else {
          answer.name[0].label.replaceAll('"', '')
          answer.name[1].label.replaceAll('"', '')
          answer.name[2].label.replaceAll('"', '')
          possibleAnswers.push(answer.name)
        }
      })

      this.possibleAnswer = newPossibleAnswer;
      this.possibleListObjectAnswer = newPossibleAnswer;
      this.possibleListAnswer = possibleAnswers;
    },

    calcLimitSection(currentItem) {
      let calc = 0;
      _.forEach(this.sectionSelected.questions, (question) => {
        calc += question.questionPunctuation;
      });
      if (currentItem) {
        if (this.editedIndexQuestion >= 0) {
          let targetQuestion = _.find(this.sectionSelected.questions, {
            id: this.editedItemQuestion.id,
          });
          if (
            targetQuestion &&
            parseFloat(targetQuestion.questionPunctuation) !==
              this.editedItemQuestion.questionPunctuation
          ) {
            this.limitSectionPunctuation =
              parseFloat(calc) +
              parseFloat(currentItem) -
              parseFloat(targetQuestion.questionPunctuation);
            this.calcSectionvalid =
              this.limitSectionPunctuation <=
              parseFloat(this.sectionSelected.punctuation);
            this.getValidPossibleAnswers();
          } else {
            this.calcSectionvalid = true;
          }
        } else {
          this.limitSectionPunctuation = parseFloat(
            calc + parseFloat(currentItem)
          );
          this.calcSectionvalid =
            this.limitSectionPunctuation <=
            parseFloat(this.sectionSelected.punctuation);
          this.getValidPossibleAnswers();
        }
      } else {
        this.limitSectionPunctuation = 0;
      }
    },

    getLimitSectionErrorText(currentItem) {
      return currentItem > 0
        ? i18n.t("survey.titles.punctuationSection") +
            this.limitSectionPunctuation +
            " > " +
            this.sectionSelected.punctuation +
            " " +
            i18n.t("survey.titles.punctuationSection2")
        : "";
    },

    getCantValidPosivelAnswers(question) {
      if (question.questionStructureType) {
        if (this.notSelect.includes(question.questionStructureType)) {
          return true;
        } else {
          return this.possibleAnswer.length > 0;
        }
      } else {
        return false;
      }
    },

    back() {
      localStorage.removeItem("survey");
      this.$router.push({ name: "survey" });
    },

    publish() {
      const changeStateDTO = {
        surveyStateType: "PUBLISHED",
        surveyId: this.surveyModel.id,
      };
      this.change_state([changeStateDTO, this.$toast]).finally(() => {
        this.back();
      });
    },
    replace(event){
      event.currentTarget.value =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceSurveyModelName(event){
      this.surveyModel.name =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceName(event){
      this.editedItemSection.name =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replacePunctuation(event){
      this.editedItemSection.punctuation =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceSearch(event){
      this.search =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceCondicionalAnswer(event){
      this.editedItemQuestion.condicionalAnswer =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceQuestionPunctuation(event){
      this.editedItemQuestion.questionPunctuation =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceQuestion(event){
      this.editedItemQuestion.question =  event.currentTarget.value.toString().replaceAll('"','')
    }
  },

  destroyed() {
    this.possibleAnswer = []
    this.possibleListAnswer = []
    this.possibleListObjectAnswer = []

    this.updateSameSession(false);
  }
};
</script>

<style scoped lang="scss">
/* This is for documentation purposes and will not be needed in your application */
#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}

.punctuation-ok {
  background-color: var(--v-success-base);
  padding: 7px;
  border-radius: 0.3em;
  width: 100px !important;
  height: 30px !important;
  text-align: center;
  display: inline-table;
}
.punctuation-error {
  background-color: var(--v-error-base);
  padding: 7px;
  border-radius: 0.3em;
  width: 100px !important;
  height: 30px !important;
  text-align: center;
  display: inline-table;
}

:deep(.v-expansion-panel) {
  border: 1px solid gray;
  border-radius: .3rem;
}

:deep(.v-expansion-panel:before) {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
</style>
